import React, { lazy, Suspense } from "react";
import { Routes as RouterDomRoutes, Route, Navigate, RouteProps, useParams, Outlet } from "react-router-dom";
import AuthorizedRoute, { IAuthorizedRouteProps } from "@client/components/authorized-route";
import BcmInvoiceDownload from "@client/pages/bcm-invoice-download";
import { TransparentLoader } from "components/loader";
import SecureRoute from "./auth/with-authentication";
import { PermissionName, signupPermissions } from "./auth/permissions";
import { AuthorizedRouteWithRRD } from "./components/authorized-route/AuthorizedRoute";
import { GetStartedWrapper } from "./auth";

const Layout = lazy(() => import("@client/components/layout"));
const Login = lazy(() => import("./pages/login"));
const ResetPassword = lazy(() => import("./pages/reset-password"));
const Signup = lazy(() => import("./pages/signup"));
const NewOrganisation = lazy(() => import("./pages/new-client/NewClient"));
const NewOrganisationSetup = lazy(() => import("./pages/new-client/new-client-setup"));
const Users = lazy(() => import("./pages/users"));
const Payments = lazy(() => import("./pages/payments"));
const Payment = lazy(() => import("./pages/payment"));
const Organisation = lazy(() => import("./pages/organisation"));
const ListPaycadaApiOrgs = lazy(() => import("./pages/paycada-api/list-paycada-api-orgs"));
const Stripe = lazy(() => import("./pages/organisation/Stripe"));
const Payout = lazy(() => import("./pages/organisation/Payout"));
const TemplatesList = lazy(() => import("./pages/templates/TemplatesList"));
const ConfigsList = lazy(() => import("./pages/new-smtp-configuration/ConfigsList"));
const SendCommunication = lazy(() => import("./pages/send-communication"));
const InvoiceSync = lazy(() => import("./pages/accounting-system-sync"));
const CreditManagementCustomerCommunication = lazy(() => import("./pages/credit-manager-customer-communication"));
const SearchCustomerForPaymentLink = lazy(
    () => import("./pages/credit-manager-customer-communication/search-customer-and-send-link")
);
const BillingCustomers = lazy(() => import("./pages/billing-customers"));
const ClientSubscription = lazy(() => import("./pages/client-subscription"));
const Customers = lazy(() => import("./pages/customers"));
const Customer = lazy(() => import("./pages/customer"));
const Profile = lazy(() => import("./pages/profile"));
const NotFound = lazy(() => import("./pages/not-found"));
const SelectOrganisation = lazy(() => import("./pages/select-organisation"));
const BrandingSettings = lazy(() => import("./pages/branding-settings"));
const Settings = lazy(() => import("./pages/settings"));
const Verify = lazy(() => import("./pages/verify"));
const SetupProfile = lazy(() => import("./pages/setup-profile"));
const Billing = lazy(() => import("./pages/billing"));
const PaymentsSettings = lazy(() => import("./pages/payments-settings"));
const BrandingPreview = lazy(() => import("./pages/branding-preview"));
const AccountingSoftware = lazy(() => import("./pages/accounting-software"));
const GetStarted = lazy(() => import("./pages/get-started"));
const ClientState = lazy(() => import("./pages/client-state"));
const ReviewApplication = lazy(() => import("./pages/review-application"));
const ClientSettings = lazy(() => import("./pages/client-settings"));
const Affiliate = lazy(() => import("./pages/affiliate"));
const Messages = lazy(() => import("./pages/messages"));
const CustomerCommunication = lazy(() => import("./pages/customer-communication"));
const CodatComplete = lazy(() => import("@client/pages/codat-complete"));
const ScheduleDataExport = lazy(() => import("./pages/data-export-schedule"));

const sharedRoutes: AuthorizedRouteWithRRD[] = [
    {
        path: "/users",
        permission: PermissionName.OrgReadUserTeams,
        element: <Users />,
    },
    {
        path: "/profile",
        element: <Profile />,
    },
    {
        path: "*",
        element: <Navigate to="profile" />,
    },
];

const clientOnlyRoutes: AuthorizedRouteWithRRD[] = [
    {
        path: "/",
        element: <Customers />,
        permission: PermissionName.DebtClientReadCustomers,
    },
    {
        path: "/get-started",
        element: <GetStarted />,
        permission: PermissionName.OrgClientReadGetStartedState,
    },
    {
        path: `/customers/:id/*`,
        permission: PermissionName.DebtClientReadCustomers,
        element: (
            <RouterDomRoutes>
                {["/invoices", "/actions", "/payments", "*"].map((path) => (
                    <Route key={path} path={path} element={<Customer />} />
                ))}
            </RouterDomRoutes>
        ),
    },
    {
        path: "/payments",
        permission: PermissionName.PaymentClientReadPayments,
        element: <Payments />,
    },
    {
        path: `/payments/:id`,
        permission: PermissionName.PaymentClientReadPayments,
        element: <Payment />,
    },
    {
        path: "/settings/branding",
        permission: PermissionName.OrgClientWriteThemeSettings,
        element: <BrandingSettings />,
    },
    {
        path: "/settings/organisation",
        permission: [PermissionName.OrgReadSettings, PermissionName.OrgClientReadAffiliateSchemeState],
        mode: "any",
        element: <Settings />,
    },
    {
        path: "/settings/billing",
        element: <Billing />,
        permission: PermissionName.OrgClientReadPlans,
    },
    {
        path: "/settings/payments",
        element: <PaymentsSettings />,
        permission: [PermissionName.OrgClientCreatePaymentAccount, PermissionName.OrgClientReadBankTransferSettings],
        mode: "any",
    },
    {
        path: "/settings/accounting-software",
        element: <AccountingSoftware />,
        permission: PermissionName.OrgClientReadAccountingSystem,
    },
    {
        path: "/settings/customer-communication",
        element: <CustomerCommunication />,
        permission: PermissionName.OrgReadSettings,
    },
];

const itOpsOnlyRoutes: AuthorizedRouteWithRRD[] = [
    {
        path: "/",
        element: <Navigate to="users" />,
    },
    {
        path: "/create",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        element: <Organisation />,
    },
    {
        path: "/paycada-api",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        element: <ListPaycadaApiOrgs />,
    },
    {
        path: "/stripe",
        permission: PermissionName.OrgItOpsSetPaymentSystemIdForClient,
        element: <Stripe />,
    },
    {
        path: "/templates",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        element: <TemplatesList />,
    },
    {
        path: "/test-payout",
        permission: PermissionName.OrgItOpsCreateClientTestPayout,
        element: <Payout />,
    },
    {
        path: "/send-communication",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        element: <SendCommunication />,
    },
    {
        path: "/smtp-config",
        permission: PermissionName.OrgItOpsCreateClientOrg,
        element: <ConfigsList />,
    },
    {
        path: "/sync-invoice",
        permission: PermissionName.OrgItOpsPerformAccountingSystemSync,
        element: <InvoiceSync />,
    },
    {
        path: "/billing-customers",
        permission: PermissionName.OrgItOpsCallCreateOrUpdateEventOnClients,
        element: <BillingCustomers />,
    },
    {
        path: "/client-subscription",
        permission: PermissionName.OrgItOpsWriteSubscriptions,
        element: <ClientSubscription />,
    },
    {
        path: "/client-theme",
        permission: PermissionName.OrgItOpsWriteThemeSettings,
        element: <BrandingSettings />,
    },
    {
        path: "/client-state",
        element: <ClientState />,
        permission: PermissionName.OrgItOpsEnableDisableClient,
    },
    {
        path: "/client-settings",
        element: <ClientSettings />,
        permission: PermissionName.ItOpsReadClientSettings,
    },
    {
        path: "/create-affiliate",
        element: <Affiliate />,
        permission: PermissionName.ItOpsWriteAffiliate,
    },
    {
        path: "/messages",
        element: <Messages />,
        permission: PermissionName.OrgItOpsRaiseMessage,
    },
    {
        path: "/schedule-data-export",
        element: <ScheduleDataExport />,
        permission: PermissionName.OrgItOpsRaiseMessage,
    },
];

const creditManagementOnlyRoutes: (IAuthorizedRouteProps & RouteProps)[] = [
    {
        path: "/",
        element: <Navigate to="send-payment-link" />,
    },
    {
        path: "/send-payment-link",
        permission: PermissionName.CreditManagerSendPaymentLink,
        element: <SearchCustomerForPaymentLink />,
    },
    {
        path: `/send-payment-link/:id`,
        permission: PermissionName.CreditManagerSendPaymentLink,
        element: <CreditManagementCustomerCommunication />,
    },
    {
        path: "/review-application",
        element: <ReviewApplication />,
        permission: PermissionName.CreditManagerReviewClientApplication,
    },
];

const allClientRoutes: AuthorizedRouteWithRRD[] = clientOnlyRoutes.concat(sharedRoutes);
const allItOpsRoutes: AuthorizedRouteWithRRD[] = itOpsOnlyRoutes.concat(sharedRoutes);
const allCreditManagementRoutes: AuthorizedRouteWithRRD[] = creditManagementOnlyRoutes.concat(sharedRoutes);

const SpecificRoutes = () => {
    const { org } = useParams();
    let routes;
    switch (org) {
        case "it-ops":
            routes = allItOpsRoutes;
            break;
        case "credit-management":
            routes = allCreditManagementRoutes;
            break;

        default:
            routes = allClientRoutes;
            break;
    }

    return (
        <Suspense fallback={<TransparentLoader loading sx={{ mt: 12 }} />}>
            <RouterDomRoutes>
                {routes.map(({ path, permission, element, mode }) => (
                    <Route
                        path={path}
                        key={path}
                        element={
                            <AuthorizedRoute permission={permission} mode={mode}>
                                {element}
                            </AuthorizedRoute>
                        }
                    />
                ))}
            </RouterDomRoutes>
        </Suspense>
    );
};

const Routes: React.FC = () => (
    <RouterDomRoutes>
        <Route
            path="/signup"
            element={
                <AuthorizedRoute permission={signupPermissions}>
                    <NewOrganisationSetup />
                </AuthorizedRoute>
            }
        />
        <Route
            path="/codat-complete"
            element={
                <AuthorizedRoute permission={signupPermissions}>
                    <CodatComplete />
                </AuthorizedRoute>
            }
        />
        <Route
            path="/branding-preview"
            element={
                <AuthorizedRoute>
                    <BrandingPreview />
                </AuthorizedRoute>
            }
        />
        <Route
            path="/download-pdf"
            element={
                <AuthorizedRoute permission={PermissionName.CreditManagerLoadInvoiceDocument}>
                    <BcmInvoiceDownload />
                </AuthorizedRoute>
            }
        />
        <Route
            element={
                <GetStartedWrapper>
                    <Layout>
                        <Outlet />
                    </Layout>
                </GetStartedWrapper>
            }
        >
            <Route path="*" element={<SpecificRoutes />} />
        </Route>
    </RouterDomRoutes>
);

const App = () => (
    <RouterDomRoutes>
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/new-organisation" element={<NewOrganisation />} />
        {["/", "/login"].map((path) => (
            <Route path={path} key={path} element={<Login />} />
        ))}
        <Route path="/select-organisation" element={<SelectOrganisation />} />
        <Route path="/setup-profile" element={<SetupProfile />} />
        <Route path="/verify" element={<Verify />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route
            path="/:org/*"
            element={
                <SecureRoute>
                    <Routes />
                </SecureRoute>
            }
        />
        <Route path="*" element={<Navigate to="/not-found" />} />
    </RouterDomRoutes>
);

export default App;
