import React, { FC, PropsWithChildren, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { TransparentLoader } from "components/loader";
import { useGetStartedQuery } from "@client/utils/query";
import { useCanShowGetStarted } from "@client/utils/hooks";
import { usePermissions } from "./with-authentication";
import useOrg from "./use-org";
import { PermissionName, signupPermissions } from "./permissions";

export interface IRedirectRoute {
    fromPath: string;
    toPath: string;
}

const GetStartedWrapper: FC<PropsWithChildren> = ({ children }) => {
    const permissions = usePermissions();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { org, isSetupComplete } = useOrg();
    const [shouldRender, setShouldRender] = useState<boolean>(false);

    const { notFinished, loaded, loading } = useGetStartedQuery({ disableLoad: false, shouldReset: true });
    const canShowGetStarted = useCanShowGetStarted();

    useEffect(() => {
        if (shouldRender) {
            return;
        }

        if (!isSetupComplete) {
            const path = signupPermissions.every((p) => permissions.includes(p)) ? `/${org}/signup` : "/not-found";
            navigate(path, { replace: true });
            return;
        }

        if (!loaded || loading) {
            return;
        }

        if (
            notFinished! > 0 &&
            permissions.includes(PermissionName.OrgClientReadGetStartedState) &&
            pathname === `/${org}` &&
            canShowGetStarted
        ) {
            navigate(`/${org}/get-started`, { replace: true });
            return;
        }

        setShouldRender(true);
    }, [
        canShowGetStarted,
        isSetupComplete,
        loaded,
        loading,
        notFinished,
        org,
        pathname,
        permissions,
        shouldRender,
        navigate,
    ]);

    if (!shouldRender) {
        return <TransparentLoader loading />;
    }

    return <>{children}</>;
};

export default GetStartedWrapper;
